import { v4 as uuidv4 } from 'uuid'

/**
 * File containing constants used throughout the application
 */

/**
 * Path keys for entries
 */
export const PATH_KEYS = {
  ROOT: 'root',
  FAQ: 'faq',
  LIFE: 'life',
  DEMO: 'demo',
}

/**
 * Homepage pathkey + url
 * edge.node.frontmatter.pathKey + edge.node.frontmatter.url
 * See gatsby-node.js createPage method for explanation to the wonky root// value
 */
export const HOMEPAGE_URL = `${PATH_KEYS.ROOT}//`

/**
 * Other marketing landing pages URLs format: pathkey + url
 * see: https://docs.google.com/spreadsheets/d/14IiJUDspESnbvxweaZY3QKz-RpGIjMJ02_LRvKWPu7I/edit?usp=sharing
 */
export const FACEBOOK_LP_URL = `${PATH_KEYS.LIFE}/get-life-insurance`
export const AFFILIATES_PREFILL_LP_URL = `${PATH_KEYS.LIFE}/online-life-quote`
export const AFFILIATES_NON_PREFILL_LP_URL = `${PATH_KEYS.LIFE}/quick-online-quote`
export const SEARCH_NON_BRANDED_LP_URL = `${PATH_KEYS.LIFE}/easy-term-life`
export const SEARCH_BRANDED_LP_URL = `${PATH_KEYS.LIFE}/ethos-term-life-insurance`

export const SOFI_DEMO_LANDING_PAGE_URL = `/${PATH_KEYS.DEMO}/sofi/`
export const SOFI_LANDING_PAGE_URL = `/sofi-ethos-life-insurance/`

/**
 * Hide the legal banner
 */
export const LEGAL_BANNER_COOKIE = 'ETHOS-LEGAL-BANNER'
export const LEGAL_BANNER_COOKIE_VALUE = 'SEEN'
export const LEGAL_BANNER_SELECTION_COOKIE = 'ETHOS-LEGAL-BANNER-SELECTION'
export const LEGAL_BANNER_COOKIE_ACCEPTED = 'ACCEPTED'
export const LEGAL_BANNER_COOKIE_DECLINED = 'DECLINED'
export const COOKIE_FULL_STORY_ENABLED = 'fsEnabled'

export const isProduction = process.env.NEXT_PUBLIC_ETHOS_ENV === 'production'
export const isStaging = process.env.NEXT_PUBLIC_ETHOS_ENV === 'staging'
export const isNetlify = process.env.NEXT_PUBLIC_ETHOS_ENV === 'netlify'

/**
 * Privacy Path
 */
export const PRIVACY_SUBDOMAIN = 'privacy.ethoslife.com'

/**
 * Media types for modules
 */
export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
}

export const HIGHLIGHT_MEDIA_TYPES = {
  ...MEDIA_TYPES,
  FOREGROUND_IMAGE: 'foregroundImage',
}

/**
 * Template keys for entries
 */
export const TEMPLATE_KEYS = {
  FAQ_INDEX: 'faq-index-template',
  FAQ_SINGLE: 'faq-single-template',
}

export const AGENT_LINKS = {
  // These are used e.g. in the logo, icons and bottom of mobile nav:
  INDEX: { href: '/agents/' },
  CTA: {
    href: 'mailto:agents@ethoslife.com',
    title: 'Contact us',
  },
  SECONDARY_CTA: {
    href: '/agents-portal/login/',
    title: 'Log in',
  },
  ACCOUNT: {
    href: '/agents-portal/',
    title: 'Account',
  },
  SEARCH: {
    href: '/agents/faq/',
    title: 'Search',
  },

  // These are the main top level link and their subnav items
  NAVLINKS: [
    {
      id: uuidv4(),
      title: 'Solutions',
      subnav: {
        cta: {
          href: '/agents/',
          title: 'Ethos for Agents',
          subcopy: 'Learn more about being an Ethos agent.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/agents/agency-solutions/',
            title: 'Agency solutions',
          },
          {
            id: uuidv4(),
            href: '/agents/pnc-solutions/',
            title: 'P&C solutions',
          },
        ],
      },
    },
    {
      id: uuidv4(),
      title: 'Products',
      subnav: {
        cta: {
          href: '/agents/our-products/',
          title: 'Our products',
          subcopy: 'Instant life insurance products for everyone.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/agents/our-products/#Highlight-1',
            title: 'Term life',
          },
          {
            id: uuidv4(),
            href: '/agents/our-products/#Highlight-2',
            title: 'Final expense whole life',
          },
          {
            id: uuidv4(),
            href: '/agents/our-products/#Highlight-3',
            title: 'Index universal life',
          },
          {
            id: uuidv4(),
            href: '/agents/our-products/#Highlight-4',
            title: 'Estate planning',
          },
          {
            id: uuidv4(),
            href: '/agents/our-products/#SupportedImage-1',
            title: 'Carriers',
          },
        ],
      },
    },
    {
      id: uuidv4(),
      title: 'Resources',
      subnav: {
        cta: {
          href: '/agents/agent-learning-center/',
          title: 'Get support',
          subcopy: 'Tailored tools at your fingertips.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/agents/agent-learning-center/',
            title: 'Learning center',
          },
          {
            id: uuidv4(),
            href: '/agents/case-studies/',
            title: 'Case studies',
          },
          {
            id: uuidv4(),
            href: '/agents/faq/',
            title: 'FAQ',
          },
        ],
      },
    },
    // {
    //   id: uuidv4(),
    //   title: 'Training',
    //   subnav: {
    //     cta: {
    //       href: '/agents/training/',
    //       title: 'Join us',
    //       subcopy: 'Jump start your career today.',
    //       id: uuidv4(),
    //       alternateIcon: false,
    //     },
    //     items: [
    //       {
    //         id: uuidv4(),
    //         href: '/agents/training/#BannerText-1',
    //         title: 'Courses & Webinars',
    //       },
    //       {
    //         id: uuidv4(),
    //         href: '/agents/training/#BannerText-2',
    //         title: 'Events',
    //       },
    //     ],
    //   },
    // },
  ],
}
// This is used to redirect to calendly from agents landing pages and retain URL parameters
export const CALENDLY_LINKS = {
  NEW_AGENT: 'calendly.com/ethos-partnerships/new-agent',
  STRATEGIC: 'calendly.com/ethos-partnerships/agency-solution-meeting',
}

// These are the links used for the navbar rewrite experiment
export const NAVBAR_LINKS = {
  // These are used e.g. in the logo, icons and bottom of mobile nav:
  INDEX: { href: '/' },
  CTA: {
    href: '/estimate-experience/',
    title: 'Check my price',
  },
  SECONDARY_CTA: {
    href: '',
    title: '',
  },
  ACCOUNT: {
    href: '/login/',
    title: 'Account',
  },
  SEARCH: {
    href: '/search/',
    title: 'Search',
  },
  LOGIN_CTA_EXPERIMENT: {
    href: '/login/?login_cta_experiment=1',
    title: 'Log in',
  },
  // Top "Learn more" CTA https://www.ethoslife.com/life/life-insurance-basics/

  // Resources

  // Life Insurance Coverage Calculator https://app.ethoslife.com/needs
  // Life Insurance Blog  https://www.ethoslife.com/blog

  // These are the main top level link and their subnav items
  NAVLINKS: [
    {
      id: uuidv4(),
      title: 'Life insurance',
      hasExpandedNav: true,
      subnav: {
        cta: {
          href: '/life/life-insurance-basics/',
          title: 'Life insurance 101',
          ctaText: 'Learn more',
          subcopy: 'A handy guide to life insurance.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            category: 'Life insurance',
            items: [
              {
                id: uuidv4(),
                href: '/life/term-life-insurance-no-medical-exam/',
                title: 'No medical exam life insurance',
              },
              {
                id: uuidv4(),
                href: '/life/life-insurance-rates-by-age/',
                title: 'Life insurance rates by age',
              },
              {
                id: uuidv4(),
                href: '/life/500000-dollar-life-insurance-policy/',
                title: '$500K life insurance',
              },
              {
                id: uuidv4(),
                href: '/life/one-million-dollar-life-insurance-policy/',
                title: '$1 million life insurance',
              },
              {
                id: uuidv4(),
                href: '/life-insurance/',
                title: 'Life insurance policy',
              },
            ],
          },
          {
            category: 'Term life',
            items: [
              {
                id: uuidv4(),
                href: '/life/term-life-policies/',
                title: 'Term life insurance',
              },
              {
                id: uuidv4(),
                href: '/life/10-year-term/',
                title: '10 year term life insurance',
              },
              {
                id: uuidv4(),
                href: '/life/20-year-term/',
                title: '20 year term life insurance',
              },
              {
                id: uuidv4(),
                href: '/life-insurance/30-year-term-life-insurance/',
                title: '30 year term life insurance',
              },
            ],
          },
          {
            category: 'Whole life',
            items: [
              {
                id: uuidv4(),
                href: '/life/whole-life-policies/',
                title: 'Whole life insurance',
              },
              {
                id: uuidv4(),
                href: '/life/gi-whole-life-insurance/',
                title: 'Guaranteed whole life insurance',
              },
              {
                id: uuidv4(),
                href: '/life-insurance/understanding-whole-life/',
                title: 'Understanding whole life insurance',
              },
              {
                id: uuidv4(),
                href: '/faq/what-is-permanent-and-whole-life-insurance/',
                title: 'Permanent and whole life insurance',
              },
            ],
          },
          {
            category: 'Final expenses',
            items: [
              {
                id: uuidv4(),
                href: '/life-insurance/end-of-life-expenses/',
                title: 'End of life expenses',
              },
              {
                id: uuidv4(),
                href: '/life-insurance/final-expense-life-insurance/',
                title: 'Burial insurance',
              },
            ],
          },
          {
            category: 'Indexed Universal Life',
            items: [
              {
                id: uuidv4(),
                href: '/life/indexed-universal-life-insurance-iul/',
                title: 'Indexed Universal Life (IUL)',
              },
            ],
          },
        ],
      },
    },
    {
      id: uuidv4(),
      hasExpandedNav: false,
      title: 'Free wills & estate plans',
      subnav: {
        cta: {
          href: '/plan/',
          title: 'Wills & estate plans 101',
          subcopy: 'Why everybody needs a plan.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/get-a-free-will/',
            title: 'Free will with life insurance',
          },
        ],
      },
    },
    {
      id: uuidv4(),
      hasExpandedNav: false,
      title: 'Why Ethos',
      subnav: {
        cta: {
          href: '/why-ethos/',
          title: 'Why Ethos',
          subcopy: 'Fast, easy life insurance you can trust.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/how-it-works/',
            title: 'How Ethos works',
          },
          {
            id: uuidv4(),
            href: '/reviews/',
            title: 'Customer reviews',
          },
        ],
      },
    },
    {
      id: uuidv4(),
      hasExpandedNav: false,
      title: 'Agents',
      subnav: {
        cta: {
          href: '/agents/',
          title: 'Ethos for agents',
          subcopy: 'Grow your business with Ethos.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/self-serve-organic/',
            title: 'Join as an agent',
          },
          {
            id: uuidv4(),
            href: '/agents-portal/login/',
            title: 'Log in as an agent',
          },
        ],
      },
    },
    {
      id: uuidv4(),
      hasExpandedNav: false,
      title: 'FAQs',
      subnav: {
        cta: {
          href: '/faq/',
          title: 'FAQs',
          subcopy: 'Everything you need to know to make a plan.',
          id: uuidv4(),
          alternateIcon: false,
        },
        items: [
          {
            id: uuidv4(),
            href: '/life-insurance-learning-center/',
            title: 'Complete guide to life insurance',
          },
          {
            id: uuidv4(),
            href: '/needs/',
            title: 'Coverage calculator',
          },
          {
            id: uuidv4(),
            href: '/contact-us/',
            title: 'Contact us',
          },
          {
            id: uuidv4(),
            href: '/blog/',
            title: 'Blog',
          },
        ],
      },
    },
  ],
}

/** FAQ Tabs Accordion Questions */
export const MAX_ACCORDION_QUESTIONS = 3

/** For use with slugify npm package: slugify(url, SLUGIFY_SETTINGS) */
export const SLUGIFY_SETTINGS = {
  lower: true,
  remove: /[*+~.()'"!:@]/g,
}

/** FAQ Questions Preview Answer Preview Length */
export const MAX_ANSWER_LENGTH = 120

/** Help Center URL - TODO: make this a CMS setting in /admin */
export const HELP_CENTER_URL = 'faq'
export const AGENTS_HELP_CENTER_URL = 'agents/faq'

/** Height of the UniversalNavbar **/
export const NAVBAR_HEIGHT = 64

/** Quote/Estimate Widget min/max eligible ages **/
export const ESTIMATE_WIDGET_AGES = {
  MIN: 20,
  MAX: 85,
}

/** Estimate Experience Widget min/max eligible ages **/
export const ESTIMATE_EXPERIENCE_AGES = {
  MIN: 20,
  MAX: 85,
}

/** Estimate Widget health classes **/
export const HEALTH_CLASSES = ['Standard', 'Preferred', 'PreferredPlus']

/** Widget flow **/
export const WIDGET_FLOW = 'widget'

/** Cards module image sizes - applicable to other modules in some cases**/
export const IMAGE_SIZES = { SMALL: 'small', LARGE: 'large', NONE: 'none' }

/** Coverage age brackets, slider defaults and maximums for estimate modules **/
export const COVERAGE_SLIDER = {
  MIN: 100000,
  STEP: 25000,
}

export const SERVER_SEGMENT_KEY =
  process.env.NEXT_PUBLIC_ETHOS_ENV === 'production'
    ? 'ukNlexiPoJVyuiUH3oKf6hExOPRYfkpl'
    : '9CPJOv8E2Xw4rftrp4tMjTWQfgj8UgBL'

export const FINAL_EXPENSE_COVERAGE = {
  MIN: 5000,
  STEP: 1000,
}

/** Invalid states for price range API calls **/
export const INVALID_STATES = ['NY']
export const VALID_STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

/** Invalid state error **/
export const STATE_ERROR =
  "We do not offer policies in your state yet but we're rapidly expanding, so check back soon!"

/** Licenses labels **/
export const LICENSES_LABELS = {
  DBA: 'DBA',
  ENTITY: 'ENTITY',
  PRODUCER: 'PRODUCER LICENSE',
  TPA: 'TPA LICENSE',
}

/** Information displayed on Estimate Widget Floating for GI **/
export const WHOLE_LIFE_BLURB = {
  INTRO:
    'Based on what we know so far, you qualify for a Whole Life policy and your coverage can start as soon as today!',
  CTA: 'Learn more about Whole Life insurance',
  LINK: '/life/whole-life-policies/',
}

export type TooltipInfoType = {
  ESTIMATE_RATE: { LABEL: string; MESSAGE: string }
  ESTIMATE_RATE_DAILY: { LABEL: string; MESSAGE: string }
  COVERAGE: { LABEL: string; MESSAGE: string }
  TERM_LENGTH: { LABEL: string; MESSAGE: string[] }
}

export const TOOLTIP: TooltipInfoType = {
  ESTIMATE_RATE: {
    LABEL: 'Monthly premium',
    MESSAGE:
      'Your estimated monthly cost, or “premium,” is the estimated total amount you will pay per month to keep a policy active. To see your real premium, apply online in just a few minutes.',
  },
  ESTIMATE_RATE_DAILY: {
    LABEL: 'Estimated daily price',
    MESSAGE:
      'This is a daily breakdown of your estimated monthly cost, or "premium", paid to keep a policy active. To see your final monthly premium, apply online in just a few minutes.',
  },
  COVERAGE: {
    LABEL: 'Coverage amount',
    MESSAGE:
      '"Coverage" is the total lump-sum amount your beneficiaries will receive if you die while your policy is active. To confirm what coverage amount you qualify for, apply online in just a few minutes.',
  },
  TERM_LENGTH: {
    LABEL: 'Term length',
    MESSAGE: [
      'Term life insurance policies last for a specific amount of time (your term) and the death benefit is only payable to beneficiaries upon the death of the insured person during the term.',
      `Because a term life policy costs less than a whole life policy, and because many policyholders' financial obligations are limited in time (for example, raising children), a term life policy makes the most sense for many people.`,
    ],
  },
}

/** Pages that are directly viewable through the agent portal **/
export const AGENT_PORTAL_EMBEDDED_PAGES = [
  'agents/3-winning-strategies',
  'agents/facebook-lead-gen',
  'agents/now-what',
  'agents/get-paid',
  'agents/faqs',
  'agents/legal',
  'demo/AgentResources',
  'agents/agent-resources',
]

/** Estate planning url -- should be folded into CMS in time */
export const ESTATE_PLANNING_URL = 'plan.ethoslife.com'

// Editor redirect url: /ep-app
export const EP_URL = 'estateplanning.ethoslife.com'

// Editor redirect: /tomorrow-app
export const TOMORROW_APP_URL = 'app.tomorrow.me'

export const ESTIMATE_PRICE_RANGE_COPY = {
  MONTHLY: 'Estimated monthly rate',
  DAILY: 'Estimated daily rate',
}

// 365.25 days per year (leap years) / 12 months
export const AVG_MONTHLY_DAYS = 30.437

// Google review link
export const FALLBACK_GOOGLE_REVIEWS =
  'https://www.google.com/maps/place/Ethos+Life/@37.7854697,-122.4001562,17z/data=!4m7!3m6!1s0x808580621cba187b:0xca13dbd1c492f14a!8m2!3d30.3483353!4d-97.8004646!9m1!1b1'

export const FULL_STORY_SELF_SERVE_ONBOARDING_TRACKING_PATHS = [
  '/self-serve-organic/',
  '/self-serve-demand/',
  '/self-serve-ppl/',
  '/self-serve-refer/',
]

export const FULL_STORY_TRACKING_PATHS = ['/plan/', '/will-and-life-insurance/']

// For LGA term users with a max coverage of at least 2M & max term of at least 30 years
export const DAILY_PREMIUM_DEFAULTS = {
  TERM: 20,
  COVERAGE: 1000000,
}

// utm_term values to exclude from showing daily premium pricing
// yellow highlighted rows from this document:
// https://docs.google.com/spreadsheets/d/1C5aRPAif3iRNqrOqwuxrsyZ7KKmAAnHCrBwcgqF0Zqg/edit?usp=sharing
export const DAILY_PRICING_CPL_EXCLUSIONS = [
  '1985270',
  '137710',
  '1421671',
  '2480529',
  '1928938',
  '2089618',
  '1981409',
  '2213888',
  '1191090',
  '1977383',
  '59530',
]

export const CIO_QUERY_PARAM = 'cioToken'

export const CIO_COOKIE_KEY = `x-cio-token`

export const CMS_REFERRAL_PATH_COOKIE_KEY = 'cms_referral_pathname'

export const WILLS_PLAN_PRICE = {
  basicWillPlanPrice: 149,
  fullEstatePlanPrice: 349,
  treatmentBasicWillPlanPrice: 199,
  treatmentFullEstatePlanPrice: 479,
}
