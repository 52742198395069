//@ts-nocheck
import { track as trackCustomerIo } from '../../../src/components/global/CustomerIo'
// import { LOCAL_STORAGE } from '../constants'
import {
  BUTTONS,
  ESTIMATE_WIDGET,
  ETHOSPIXELID,
  EVENT_NAMES,
  MODULES,
  ModuleValues,
  ModulesType,
} from './constants'
import { trackSegmentEvent } from './trackSegmentEvent'

export const coreWebVitalsAnalytics = {
  trackWebVitals: ({
    properties: { name, id, value, url, label, userId, deviceType },
  }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.WEB_VITALS,
      properties: {
        name,
        id,
        value,
        url,
        label,
        userId,
        deviceType,
      },
    })
  },
}

export const genericEvents = {
  questionShown: ({ questionId }) => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_SHOWN,
      properties: {
        questionId,
      },
    })
  },
  questionAnswered: ({ questionId, answer }) => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_ANSWERED,
      properties: {
        questionId,
        answer,
      },
    })
  },
}

export const cmsModuleAnalytics = {
  trustPilotReviewClicked: ({ title, webLink }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.TRUST_PILOT_REVIEW_CLICKED,
      properties: { title, webLink, module: MODULES.TRUST_PILOT },
    })
  },
  emailSubmitted: ({ email, campaignName }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.EMAIL_SUBMITTED,
      properties: { email, campaignName },
    })
  },
  trackScrollDepth: ({ properties }): void => {
    // const visibleModules = localStorage.getItem(LOCAL_STORAGE.VISIBLE_MODULES)
    trackSegmentEvent({
      event: EVENT_NAMES.SCROLL_DEPTH_RECORDED,
      properties: {
        ...properties,
        // visibleModules,
      },
    })
  },
  headerCtaClicked: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.HEADER,
        ctaLabel: BUTTONS.CTA,
      },
    })
  },
  homepageMainCtaClicked: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.HOMEPAGE,
        ctaLabel: BUTTONS.MAIN_CTA,
      },
    })
  },
  intentHeaderClicked: ({ ctaLabel }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.INTENT_HOMEPAGE,
        ctaLabel,
      },
    })
  },
  loginCTAExposed: ({
    treatment,
    userStatus,
    isMobile,
  }: {
    treatment: string
    userStatus: 'LoggedIn' | 'LoggedOut'
    isMobile: boolean
  }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.LOGIN_CTA_EXPOSED,
      properties: {
        module: MODULES.NAVBAR,
        treatment,
        userStatus,
        isMobile,
      },
    })
  },
  navbarCtaClicked: ({
    ctaLabel,
    clickthroughUrl,
    ...properties
  }: {
    ctaLabel: string
    clickthroughUrl: string
  } & Record<string, unknown>): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.NAVBAR,
        ctaLabel,
        clickthroughUrl,
        ...properties,
      },
    })
  },
  navbarSecondaryCtaClicked: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.SECONDARY_CTA_CLICKED,
      properties: {
        module: MODULES.NAVBAR,
      },
    })
  },
  navbarItemClicked: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.NAV_ITEM_CLICKED,
      properties,
    })
  },
  ctaClicked: ({
    properties,
    callback,
  }: {
    properties?: any
    callback?: any
  } = {}): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties,
      callback,
    })
  },
  agentLeadSubmitted: (formData): void => {
    window.fbq &&
      window.fbq('init', ETHOSPIXELID, {
        em: formData.email,
        ph: formData.phone,
        st: formData.state,
      })
    trackSegmentEvent({
      event: EVENT_NAMES.AGENT_LEAD_SUBMITTED,
      properties: {
        producerCount: formData.producerCount,
        policyCount: formData.policyCount,
        role: formData.role,
        license: formData.license,
        client: formData.client,
        lastName: formData.name,
      },
    })
  },
  agentPhoneClicked: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.AGENT_PHONE_CLICKED,
      properties: {
        module: MODULES.AGENT_LEAD_FORM,
      },
    })
  },
  regionSelected: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.HOMEPAGE,
        eventType: EVENT_NAMES.REGION_SELECTED,
        state: properties.state,
      },
    })
  },
  zipCodeCheck: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.HOMEPAGE,
        eventType: EVENT_NAMES.ZIP_CODE_CHECK,
        state: properties.state,
        zipCode: properties.zipCode,
        zipCodeValid: properties.zipCodeValid,
      },
    })
  },
  agentResourceClicked: ({ title, partnerId }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.RESOURCE_CLICKED,
      properties: {
        title,
        partnerId,
      },
    })
  },
  agentResourceContentClicked: ({ partnerId, href, title, header }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.RESOURCE_CONTENT_CLICKED,
      properties: {
        partnerId,
        href,
        title,
        header,
      },
    })
  },
  agentResourceAccordionClicked: ({ partnerId, title, collapsed }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.RESOURCE_ACCORDION_CLICKED,
      properties: {
        partnerId,
        header: title,
        collapsed,
      },
    })
  },
  accordionExpanded: ({ key, title, value }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ACCORDION_EXPANDED,
      properties: {
        key,
        title,
        value,
      },
    })
  },
  submittedWillsModalForm: (formData): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.WILLS_MODAL_FORM_SUBMITTED,
      properties: formData,
    })
  },
  willsModalDualCtaOptionSelected: (option: 'expert' | 'yourself'): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.WILLS_MODAL_DUAL_CTA_OPTION_SELECTED,
      properties: {
        option,
      },
    })
  },
  willsCancelationFormSubmit: ({ cancellationReason }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.WILLS_CANCELLATION_FORM_SUBMITTED,
      properties: {
        cancellationReason,
      },
    })
  },
}

/**
 * CMS SearchIndex module analytics events
 */

export const searchModuleAnalytics = {
  termSearched: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.TERM_SEARCHED,
      properties: {
        module: MODULES.SEARCH_INDEX,
        ...properties,
      },
    })
  },
  resultClicked: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.RESULT_CLICKED,
      properties: {
        module: MODULES.SEARCH_INDEX,
        ...properties,
      },
    })
  },
}

/**
 * CMS PartnerSignup module analytics events
 */

export const partnerSignupModuleAnalytics = {
  partnerAgentCreateSubmitting: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.PARTNER_AGENT_CREATE_SUBMITTING,
      properties: {
        module: MODULES.PARTNER_SIGNUP,
        ...properties,
      },
    })
  },
  agreementDocLinkClicked: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.AGREEMENT_DOC_LINK_CLICKED,
      properties: {
        module: MODULES.PARTNER_SIGNUP,
      },
    })
  },
}

/**
 * CMS LegalBanner analytics event
 */

export const legalBannerAnalytics = {
  legalBannerDismissed: (properties?: Record<string, any>): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.LEGAL_BANNER_DISMISSED,
      properties: {
        ...properties,
      },
    })
  },
  legalBannerShown: (properties?: Record<string, any>): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.LEGAL_BANNER_SHOWN,
      properties: {
        ...properties,
      },
    })
  },
}

/**
 * CMS Estimate Widget analytics events.
 * Note: The functions here must match
 * estimateExperienceAnalytics since a generic "analytics"
 * function is passed in for both!
 */
export const estimateWidgetAnalytics = {
  // === Begin MUST MATCH estimateExperienceAnalytics ===
  birthDateCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.BIRTH_DATE_COMPLETED,
        birthday: properties.birthDate,
      },
    })
  },
  userPrefilled: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.USER_PREFILLED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
      },
    })
  },
  inputFormTouched: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.INPUT_FORM_TOUCHED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  coverageChanged: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.COVERAGE_CHANGED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        coverage: properties.coverage,
        term: properties.term,
        previousCoverage: properties.previousCoverage,
      },
    })
  },
  termChanged: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.TERM_CHANGED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        coverage: properties.coverage,
        term: properties.term,
        previousTerm: properties.previousTerm,
      },
    })
  },
  defaultCoverageAndTermSet: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.DEFAULT_COVERAGE_AND_TERM_SET,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        coverage: properties.coverage,
        term: properties.term,
      },
    })
  },
  initialQuotes: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.INITIAL_QUOTES,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        product: properties.product,
        coverage: properties.coverage,
        term: properties.term,
        minPrice: properties.minPrice,
        maxPrice: properties.maxPrice,
      },
    })
  },
  genderCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.GENDER_COMPLETED,
        gender: properties.gender,
      },
    })
  },
  healthCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.HEALTH_COMPLETED,
        health: properties.health,
      },
    })
  },
  tobaccoCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.TOBACCO_COMPLETED,
        smoker: properties.smoker,
      },
    })
  },
  regionSelected: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.REGION_SELECTED,
        state: properties.state,
      },
    })
  },
  zipCodeCheck: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.ZIP_CODE_CHECK,
        state: properties.state,
        zipCode: properties.zipCode,
        zipCodeValid: properties.zipCodeValid,
      },
    })
  },
  zipCodeCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.ZIP_CODE_COMPLETED,
        zipCode: properties.zipCode,
      },
    })
  },
  estimatedCreditCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.ESTIMATED_CREDIT_COMPLETED,
        estimatedCredit: properties.estimatedCredit,
      },
    })
  },
  desiredCoverageCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        eventType: ESTIMATE_WIDGET.DESIRED_COVERAGE_COMPLETED,
        desiredCoverage: properties.desiredCoverage,
      },
    })
  },
  continuedToSecondView: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ctaLabel: BUTTONS.CALCULATE,
        priceElasticityEnabled: properties.priceElasticityEnabled,
      },
    })
  },
  editAnswers: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.RETURNED_TO_ANSWERS,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
      },
    })
  },
  adjustedEstimatePrice: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ADJUSTED_PRICE,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  goToMainApp: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.USER_REDIRECTED_TO_MAIN_APP,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ctaLabel: BUTTONS.START_APPLYING,
        ...properties,
      },
    })
  },
  continuedToApp: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ctaLabel: BUTTONS.START_APPLYING,
        ...properties,
      },
    })
  },
  continueToPrices: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ESTIMATES_CONTINUE_TO_PRICES,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  continueToLeadForm: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ESTIMATES_CONTINUE_TO_LEAD_FORM,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  continueToSales: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ESTIMATES_CONTINUE_TO_SALES,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  leadFormSubmitted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.LEAD_FORM_SUBMITTED,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  continueToPricesError: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ESTIMATES_CONTINUE_TO_PRICES_ERROR,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  continueToSalesError: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ESTIMATES_CONTINUE_TO_SALES_ERROR,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  userInteractedWithField: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.USER_INTERACTED_WITH_FIELD,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },

  // === End MUST MATCH estimateExperienceAnalytics ===

  // ==== Saving Email ====
  savedEstimates: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.EMAILED_ESTIMATES,
      properties: {
        module: MODULES.ESTIMATE_WIDGET,
        ...properties,
      },
    })
  },
  emailSender: ({ properties }): void => {
    trackCustomerIo({
      ...properties,
    })
  },
  getMyEstimate: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        ctaLabel: BUTTONS.GET_MY_ESTIMATE,
      },
    })
  },
}

/**
 * CMS Estimate Experience analytics events
 * Note: The functions here must match
 * estimateWidgetAnalytics since a generic "analytics"
 * function is passed in for both!
 */

export const estimateExperienceAnalytics = {
  // === Begin MUST MATCH estimateWidgetAnalytics ===
  birthDateCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.BIRTH_DATE_COMPLETED,
        birthday: properties.birthDate,
      },
    })
  },
  genderCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.GENDER_COMPLETED,
        gender: properties.gender,
      },
    })
  },
  healthCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.HEALTH_COMPLETED,
        health: properties.health,
      },
    })
  },
  tobaccoCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.TOBACCO_COMPLETED,
        smoker: properties.smoker,
      },
    })
  },
  regionSelected: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.REGION_SELECTED,
        state: properties.state,
      },
    })
  },
  zipCodeCheck: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.ZIP_CODE_CHECK,
        state: properties.state,
        zipCode: properties.zipCode,
        zipCodeValid: properties.zipCodeValid,
      },
    })
  },
  zipCodeCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.ZIP_CODE_COMPLETED,
        zipCode: properties.zipCode,
      },
    })
  },
  estimatedCreditCompleted: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.QUESTION_COMPLETED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        eventType: ESTIMATE_WIDGET.ESTIMATED_CREDIT_COMPLETED,
        estimatedCredit: properties.estimatedCredit,
      },
    })
  },
  continuedToSecondView: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        ctaLabel: BUTTONS.CALCULATE,
        priceElasticityEnabled: properties.priceElasticityEnabled,
      },
    })
  },

  editAnswers: (): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.RETURNED_TO_ANSWERS,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
      },
    })
  },
  adjustedEstimatePrice: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.ADJUSTED_PRICE,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        ...properties,
      },
    })
  },
  continuedToApp: ({ properties }): void => {
    trackSegmentEvent({
      event: EVENT_NAMES.CTA_CLICKED,
      properties: {
        module: MODULES.ESTIMATE_EXPERIENCE,
        ctaLabel: BUTTONS.START_APPLYING,
        ...properties,
      },
    })
  },
  // === End MUST MATCH estimateExperienceAnalytics ===
}

/**
 * Tracks when an experiment is viewed. Fired automatically from the
 * Optimizely / Segment integration but this function is for firing
 * those events manually.
 *
 * @param {object} params
 * @property {string} experimentName
 * @property {string} variationName
 */
export const experimentViewed = ({ experimentName, variationName }): void => {
  trackSegmentEvent({
    event: EVENT_NAMES.EXPERIMENT_VIEWED,
    properties: {
      experimentName,
      variationName,
    },
  })
}
